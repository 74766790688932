import { Box, BoxOwnProps, IntentVals, NoSsr, useId } from '@stoplight/mosaic';
import { HighlightCodeFallback, useHighlight } from '@stoplight/mosaic-code-viewer';
import cn from 'clsx';
import { Language } from 'prism-react-renderer';
import * as React from 'react';

import ReactSimpleCodeEditor from '../ReactSimpleCodeEditor';

interface ICodeEditorProps extends BoxOwnProps {
  value: string;
  onChange(code: string): void;
  padding?: number;
  language?: Language;
  autoFocus?: boolean;
  showLineNumbers?: boolean;
  style?: React.CSSProperties;
  placeholder?: string;
  intent?: IntentVals;
}

export const CodeEditor = ({
  value,
  language,
  className,
  style = {},
  placeholder,
  autoFocus,
  showLineNumbers = true,
  onChange,
  padding = 12,
  intent,
  border,
  borderColor = {
    default: 'transparent',
    hover: 'input',
    focus: 'primary',
  },
  ...props
}: ICodeEditorProps) => {
  const id = useId();
  const { gutterWidth, lines, renderHighlight } = useHighlight({
    value,
    language,
    showLineNumbers,
    style,
  });

  let textAreaCss;
  if (showLineNumbers) {
    textAreaCss = `.sl-code-editor[id="${id}"] textarea {
      padding-left: ${padding + gutterWidth}px !important;
      word-break: break-all !important;
    }`;
  }

  return (
    <Box
      className={cn('sl-code-editor', className)}
      id={id}
      {...props}
      style={{
        fontFamily: style.fontFamily ?? 'var(--font-code)',
        fontSize: style.fontSize ?? 'var(--fs-code)',
        lineHeight: style.lineHeight ?? 'var(--lh-code)',
      }}
    >
      <NoSsr fallback={<HighlightCodeFallback lines={lines} />}>
        <Box
          as={ReactSimpleCodeEditor}
          style={style}
          placeholder={placeholder}
          autoFocus={autoFocus}
          value={value}
          onValueChange={onChange}
          highlight={renderHighlight}
          padding={padding}
          border={border}
          borderColor={borderColor}
        />

        {textAreaCss ? <style type="text/css" dangerouslySetInnerHTML={{ __html: textAreaCss }} /> : null}
      </NoSsr>
    </Box>
  );
};
