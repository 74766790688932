import type { Language } from 'prism-react-renderer';

import { Prism } from './prism';

export type CodeViewerLanguage =
  | Language
  | 'curl'
  | 'clojure'
  | 'csharp'
  | 'http'
  | 'java'
  | 'kotlin'
  | 'php'
  | 'powershell'
  | 'r'
  | 'ruby'
  | 'swift';

/**
 * The extra languages we load in by default that prism-react-renderer does not include
 * Note that adding languages doesn't come cheap - the below adds around 6kb (+25%) to the minified + brotli package size
 */
require('prismjs/components/prism-clojure');
require('prismjs/components/prism-csharp');
require('prismjs/components/prism-http');
require('prismjs/components/prism-java');
require('prismjs/components/prism-kotlin');
require('prismjs/components/prism-php');
require('prismjs/components/prism-powershell');
require('prismjs/components/prism-r');
require('prismjs/components/prism-ruby');
require('prismjs/components/prism-swift');

/**
 * cURL
 */

Prism.languages.curl = {
  curl: /\bcurl\b/,
  url: /https?:[a-zA-Z0-9:.?=\/\-_{}]*/,
  parameter: {
    pattern: /[A-Za-z0-9\[\]-_]+ *(?=[=])/,
  },
  value: [
    {
      pattern: /([=])([A-Za-z0-9-_.]*)/,
      lookbehind: true,
    },
    {
      pattern: /(["'])(\\(?:\r\n|[\s\S])|(?!\1)[^\\\r\n])*\1/,
    },
    {
      pattern: /(\-u )([A-Za-z0-9-_.{}]*)/,
      lookbehind: true,
    },
  ],
  option: / *-[a-zA-Z]*\b/,
};
