import ReactRendererPrism from 'prism-react-renderer/prism';

/**
 * Sets the global prism instance to the one included in prism-react-renderer if not already set on global scope
 */
const g = typeof global !== 'undefined' ? global : window;
// @ts-expect-error
if (!g.Prism) {
  // @ts-expect-error
  g.Prism = ReactRendererPrism;
}

// @ts-expect-error
const Prism = g.Prism;

export { Prism };
