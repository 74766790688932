// adapted from https://github.com/FormidableLabs/prism-react-renderer/blob/master/src/themes/nightOwl.js

import type { PrismTheme } from 'prism-react-renderer';

export const darkTheme: { mode: 'light' | 'dark' } & PrismTheme = {
  mode: 'dark',
  plain: {
    // color: '#d6deeb',
    // backgroundColor: '#011627',
  },
  styles: [
    {
      types: ['changed'],
      style: {
        color: 'rgb(162, 191, 252)',
        fontStyle: 'italic',
      },
    },
    {
      types: ['deleted'],
      style: {
        color: 'rgba(239, 83, 80, 0.56)',
        fontStyle: 'italic',
      },
    },
    {
      types: ['inserted', 'attr-name', 'function', 'url'],
      style: {
        color: '#d2a8ff',
      },
    },
    {
      types: ['string', 'attr-value'],
      style: {
        color: '#a5d6ff',
      },
    },
    {
      types: ['punctuation'],
      style: {
        color: 'inherit',
      },
    },
    {
      types: ['comment'],
      style: {
        color: '#8b949e',
      },
    },
    {
      types: ['variable'],
      style: {
        color: 'rgb(214, 222, 235)',
      },
    },
    {
      types: ['number'],
      style: {
        color: 'rgb(247, 140, 108)',
      },
    },
    {
      types: ['builtin', 'char', 'constant'],
      style: {
        color: 'rgb(130, 170, 255)',
      },
    },
    {
      types: ['selector', 'doctype', 'value'],
      style: {
        color: 'rgb(199, 146, 234)',
        fontStyle: 'italic',
      },
    },
    {
      types: ['class-name'],
      style: {
        color: '#7ee787',
      },
    },
    {
      types: ['operator', 'keyword'],
      style: {
        color: '#ff7b72',
      },
    },
    {
      types: ['boolean'],
      style: {
        color: '#79c0ff',
      },
    },
    {
      types: ['property'],
      style: {
        color: 'rgb(128, 203, 196)',
      },
    },
    {
      types: ['namespace', 'option'],
      style: {
        color: 'rgb(178, 204, 214)',
      },
    },
  ],
};
