// adapted from https://github.com/FormidableLabs/prism-react-renderer/blob/master/src/themes/github.js

import type { PrismTheme } from 'prism-react-renderer';

export const lightTheme: { mode: 'light' | 'dark' } & PrismTheme = {
  mode: 'light',
  plain: {
    // color: '#393A34',
    // backgroundColor: '#f6f8fa',
  },
  styles: [
    {
      types: ['comment', 'blockquote'],
      style: {
        color: '#969896',
        fontStyle: 'italic',
      },
    },
    {
      types: ['cdata'],
      style: {
        color: '#183691',
      },
    },
    {
      types: ['doctype', 'punctuation', 'variable', 'macro property'],
      style: {
        color: '#333',
      },
    },
    {
      types: ['important', 'rule', 'builtin'],
      style: {
        color: '#a71d5d',
      },
    },
    {
      types: ['keyword', 'operator'],
      style: {
        color: '#d73a49',
      },
    },
    {
      types: ['string', 'url', 'regex', 'attr-value'],
      style: {
        color: '#032f62',
      },
    },
    {
      types: ['property', 'number', 'boolean', 'entity', 'atrule', 'constant', 'symbol', 'command', 'code'],
      style: {
        color: '#005cc5',
      },
    },
    {
      types: ['attr-name', 'function'],
      style: {
        color: '#6f42c1',
      },
    },
    {
      types: ['selector', 'prolog'],
      style: {
        color: '#63a35c',
      },
    },
    {
      types: ['namespace', 'pseudo-element', 'class', 'class-name', 'pseudo-class', 'id', 'url-reference'],
      style: {
        color: '#22863a',
      },
    },
    {
      types: ['title'],
      style: {
        color: '#1d3e81',
        fontWeight: 'bold',
      },
    },
    {
      types: ['list'],
      style: {
        color: '#ed6a43',
      },
    },
    {
      types: ['inserted'],
      style: {
        color: '#55a532',
        backgroundColor: '#eaffea',
      },
    },
    {
      types: ['deleted'],
      style: {
        color: '#bd2c00',
        backgroundColor: '#ffecec',
      },
    },
    {
      types: ['bold'],
      style: {
        fontWeight: 'bold',
      },
    },
    {
      types: ['italic'],
      style: {
        fontStyle: 'italic',
      },
    },
    {
      languages: ['json'],
      types: ['property'],
      style: {
        color: '#183691',
      },
    },
    {
      languages: ['markup'],
      types: ['tag punctuation'],
      style: {
        color: '#333',
      },
    },
    {
      languages: ['css'],
      types: ['function'],
      style: {
        color: '#0086b3',
      },
    },
    {
      languages: ['yaml'],
      types: ['atrule'],
      style: {
        color: '#63a35c',
      },
    },
    {
      languages: ['markdown'],
      types: ['url'],
      style: {
        color: '#795da3',
      },
    },
    {
      languages: ['bash'],
      types: ['keyword'],
      style: {
        color: '#0086b3',
      },
    },

    {
      types: ['option'],
      style: {
        opacity: 0.7,
      },
    },
    {
      types: ['value'],
      style: {
        color: '#e3116c',
      },
    },

    {
      types: ['function-variable'],
      style: {
        color: '#6f42c1',
      },
    },
  ],
};
